import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGitSquare } from '@fortawesome/free-brands-svg-icons';
import Appstore from '../../assets/img/iphone/appstore.png';

function MobileProject({
  image,
  title,
  subtitle,
  appstore,
  desc,
  stack,
  link,
}) {
  return (
    <li class="iphone-project">
      <img
        src={image}
        alt=""
        class="iphone-image"
      />
      <div class="portfolio-info iphone-info">
        <h3 class="portfolio-info__subtitle">{subtitle}</h3>
        <h2 class="portfolio-info__title">{title}</h2>
        {appstore && (
          <a
            className="appstore-container"
            href="https://apps.apple.com/us/app/dotatactix/id6474991131"
          >
            <img
              className="appstore-image"
              src={Appstore}
              alt=""
            />
          </a>
        )}
        <p class="portfolio-info__text iphone-info__text">{desc}</p>
        <ul class="portfolio-info__stack">
          {stack.map((stack) => (
            <li class="portfolio-info__stack-item">{stack}</li>
          ))}
        </ul>
        <ul class="portfolio-info__icons-list">
          <li class="portfolio-info__icons-item">
            <a
              href={link}
              target="_blank"
              class="fa fa-github-alt"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faGitSquare} />
            </a>
          </li>
        </ul>
      </div>
    </li>
  );
}

export default MobileProject;
