import React from 'react';
import WorkCard from './WorkCard';

function Work() {
  return (
    <div class="block education">
      <div class="portfolio-title education-title">Experience</div>
      <div class="art-timeline art-gallery">
        <WorkCard
          company={'CMiC'}
          role={'Product Support Analyst'}
          date={'Aug 2022 - Present'}
          duties={[
            'Triage product queues and prioritize issues according to their severity',
            'Test and duplicate issues in order to document and demonstrate the problem to other teams such as programming',
            'Relay feedback to Product Managers so that the product can be improved',
            'Provide feedback to supervisors and managers regarding processes that are unclear and need to be changed',
            'Answer client queries and provide basic direction and training on common issues',
          ]}
        />
        <WorkCard
          company={'LeapGrad'}
          role={'Software Developer Intern'}
          date={'Jan 2022 - May 2022'}
          duties={[
            'Completed the environment setup, business logic, and third-party integration for the web application “LeapGrad”',
            'Added English-French integration to the web application making it accessible for more than 8,000,000+ people and expanding the audience',
            'Sourced, selected, and organized information for inclusion and design of the appearance, layout, and flow of the platform',
            'Planned, designed, wrote, modified, integrated, and tested Website related code',
          ]}
        />
      </div>
    </div>
  );
}

export default Work;
