import React from 'react';
import EducationCard from './EducationCard';

function Education() {
  return (
    <div class="block education">
      <div class="portfolio-title education-title">Education</div>
      <div class="art-timeline art-gallery">
        <EducationCard
          school={'Humber College'}
          major={'Information Technology Solutions'}
          date={'Sep 2020 - Jun 2022'}
          details={[
            'Gaining knowledge in the most popular programming languages, such as: Swift, JavaScript, Java.',
            'Related Courses:',
            'iOS Development',
            'Android Development',
            'Web Development and Design',
            'Data Structures and Design Patterns',
            'Java Programming',
          ]}
          // cert={'files/certificate.jpg'}
        />
        <EducationCard
          school={'Yonsei University'}
          major={'International Commerce'}
          date={'Mar 2016 - Feb 2020'}
          details={[
            'Majored in business administration and Korean politics.',
            'Was highly involved in multiple individual and group projects',
          ]}
        />
      </div>
    </div>
  );
}

export default Education;
