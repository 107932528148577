import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faEnvelope,
  faAt,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import phpfile from '../../mail.php';

function Contact() {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(phpfile, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formValues),
      });
      if (response.ok) {
        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
          setFormSubmitted(true);
        }, 2000);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <div className="block contact-form">
      <div className="row p-30-0">
        <div className="art-a art-card">
          <h4 className="block-title">Get in touch</h4>
          <form
            id="form"
            className="art-contact-form"
            onSubmit={handleSubmit}
          >
            <div className="art-form-field">
              <input
                id="name"
                name="name"
                className={`art-input ${formValues.name ? 'art-active' : ''}`}
                type="text"
                placeholder="Name"
                required
                value={formValues.name}
                onChange={handleInputChange}
              />
              <label htmlFor="name">
                <FontAwesomeIcon icon={faUser} />
              </label>
            </div>
            <div className="art-form-field">
              <input
                id="email"
                name="email"
                className={`art-input ${formValues.email ? 'art-active' : ''}`}
                type="email"
                placeholder="Email"
                required
                value={formValues.email}
                onChange={handleInputChange}
              />
              <label htmlFor="email">
                <FontAwesomeIcon icon={faAt} />
              </label>
            </div>
            <div className="art-form-field">
              <textarea
                id="message"
                name="message"
                className={`art-input ${
                  formValues.message ? 'art-active' : ''
                }`}
                placeholder="Message"
                required
                value={formValues.message}
                onChange={handleInputChange}
              ></textarea>
              <label htmlFor="message">
                <FontAwesomeIcon icon={faEnvelope} />
              </label>
            </div>
            <div className="art-submit-frame">
              <button
                className="art-btn art-btn-md art-submit"
                type="submit"
              >
                <span>Send message</span>
              </button>
              {formSubmitted && (
                <div
                  className={
                    showSuccess ? 'art-success visible' : 'art-success'
                  }
                >
                  <p style={{ marginRight: '5px' }}>Success</p>
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
