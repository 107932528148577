import '../css/normalize.css';
import '../css/style.css';
import './App.css';
import '../css/main.css';
import '../css/media.css';
import Vanta from '../components/Vanta/vanta';
import Hero from '../components/1_Hero/Hero';
import Skills from '../components/2_Skills/Skills';
import MobilePortfolio from '../components/3_MobilePortfolio/MobilePortfolio';
import WebPortfolio from '../components/4_WebPortfolio/WebPortfolio';
import Work from '../components/5_Work/Work';
import Bootcamps from '../components/6_Bootcamps/Bootcamps';
import Education from '../components/7_Education/Education';
import Contact from '../components/8_Contact/Contact';
import Footer from '../components/9_Footer/Footer';
import Preloader from '../components/Preloader/Preloader';
import React, { useState } from 'react';

const App = () => {
  const [contentLoaded, setContentLoaded] = useState(false);
  setTimeout(() => {
    setContentLoaded(true);
  }, 3000);

  return (
    <>
      <Preloader />
      <div className={`main ${contentLoaded ? 'show' : ''}`}>
        <Hero />
        <Skills />
        <MobilePortfolio />
        <WebPortfolio />
        <Work />
        <Bootcamps />
        <Education />
        <Contact />
        <Footer />
      </div>
      <Vanta></Vanta>
    </>
  );
};

export default App;
