/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import compl from '../../assets/img/portfolio/compl.jpg';
import taxi from '../../assets/img/portfolio/taxi.jpg';
import bite from '../../assets/img/portfolio/bite.jpg';
import beats from '../../assets/img/portfolio/76162565.png';
import airAsia from '../../assets/img/portfolio/Screenshot 2021-08-18 161436.png';

function WebPortfolio() {
  return (
    <div class="block portfolio">
      <div class="portfolio-container">
        <input
          id="_1"
          type="checkbox"
        />
        <label
          class="art-btn art-submit resume-btn portfolio-resume show-more"
          for="_1"
        ></label>
        <ul class="portfolio-list">
          <li class="portfolio-item">
            <div class="portfolio-pic">
              <a
                href="https://capstone-humber.netlify.app/"
                class="portfolio-pic__link"
                target="_blank"
                rel="noreferrer"
              ></a>
              <img
                src={compl}
                alt=""
                class="portfolio-img"
              />
            </div>
            <div class="portfolio-info">
              <h3 class="portfolio-info__subtitle">Featured Project</h3>
              <h2 class="portfolio-info__title">Daily Compliance</h2>
              <p class="portfolio-info__text">
                Daily Compliance is a B2B SaaS-based solution to manage and
                streamline compliance affairs of SMB manufacturing companies.
                The straightforward platform can help to provide a quick and
                convenient way to trace the products’ compliance status.
              </p>
              <ul class="portfolio-info__stack">
                <li class="portfolio-info__stack-item">MERN</li>
                <li class="portfolio-info__stack-item">Material UI</li>
                <li class="portfolio-info__stack-item">Stripe</li>
                <li class="portfolio-info__stack-item">DocuSign</li>
              </ul>
              <ul class="portfolio-info__icons-list">
                <li class="portfolio-info__icons-item">
                  <a
                    href="https://github.com/mishapark/capstone-heroku"
                    target="_blank"
                    class="fa fa-github-alt"
                    rel="noreferrer"
                  ></a>
                </li>
                <li class="portfolio-info__icons-item">
                  <a
                    href="https://capstone-humber.netlify.app/"
                    target="_blank"
                    class="fa fa-external-link"
                    rel="noreferrer"
                  ></a>
                </li>
              </ul>
            </div>
          </li>

          <li class="portfolio-item">
            <div class="portfolio-pic">
              <a
                href="https://mp-lofttaxi.herokuapp.com/"
                class="portfolio-pic__link"
                target="_blank"
                rel="noreferrer"
              ></a>
              <img
                src={taxi}
                alt=""
                class="portfolio-img"
              />
            </div>
            <div class="portfolio-info">
              <h3 class="portfolio-info__subtitle">Featured Project</h3>
              <h2 class="portfolio-info__title">LoftTaxi</h2>
              <p class="portfolio-info__text">
                React/Redux web application that allows user to authorize,
                select start/end points on the map and call a taxi
              </p>
              <ul class="portfolio-info__stack">
                <li class="portfolio-info__stack-item">React/Redux Saga</li>
                <li class="portfolio-info__stack-item">Material UI</li>
                <li class="portfolio-info__stack-item">REST API</li>
              </ul>
              <ul class="portfolio-info__icons-list">
                <li class="portfolio-info__icons-item">
                  <a
                    href="https://github.com/mishapark/loftreact"
                    target="_blank"
                    class="fa fa-github-alt"
                    rel="noreferrer"
                  ></a>
                </li>
                <li class="portfolio-info__icons-item">
                  <a
                    href="https://mp-lofttaxi.herokuapp.com/"
                    target="_blank"
                    class="fa fa-external-link"
                    rel="noreferrer"
                  ></a>
                </li>
              </ul>
            </div>
          </li>

          <li class="portfolio-item">
            <div class="portfolio-pic">
              <a
                href="https://bite-mean.netlify.app/"
                class="portfolio-pic__link"
                target="_blank"
                rel="noreferrer"
              ></a>
              <img
                src={bite}
                alt=""
                class="portfolio-img"
              />
            </div>
            <div class="portfolio-info">
              <h3 class="portfolio-info__subtitle">Featured Project</h3>
              <h2 class="portfolio-info__title">Bite.ca</h2>
              <p class="portfolio-info__text">
                A highley responsive full-stack application inspired by Yelp
                with the usage of Mapbox
              </p>
              <ul class="portfolio-info__stack">
                <li class="portfolio-info__stack-item">MERN</li>
                <li class="portfolio-info__stack-item">Mapbox</li>
                <li class="portfolio-info__stack-item">Material UI</li>
              </ul>
              <ul class="portfolio-info__icons-list">
                <li class="portfolio-info__icons-item">
                  <a
                    href="https://github.com/mishapark/mean-project"
                    target="_blank"
                    class="fa fa-github-alt"
                    rel="noreferrer"
                  ></a>
                </li>
                <li class="portfolio-info__icons-item">
                  <a
                    href="https://bite-mean.netlify.app/"
                    target="_blank"
                    class="fa fa-external-link"
                    rel="noreferrer"
                  ></a>
                </li>
              </ul>
            </div>
          </li>

          <li class="portfolio-item">
            <div class="portfolio-pic">
              <a
                href="https://mishapark.github.io/dist/"
                class="portfolio-pic__link"
                target="_blank"
                rel="noreferrer"
              ></a>
              <img
                src={beats}
                alt=""
                class="portfolio-img"
              />
            </div>
            <div class="portfolio-info">
              <h3 class="portfolio-info__subtitle">Featured Project</h3>
              <h2 class="portfolio-info__title">Beats Solo 3</h2>
              <p class="portfolio-info__text">
                A highley responsive product website with implementation of such
                features as slick-slider, form validation, accordion, Youtube
                IFrame API and Yandex Maps API.
              </p>
              <ul class="portfolio-info__stack">
                <li class="portfolio-info__stack-item">jQuery</li>
                <li class="portfolio-info__stack-item">SASS</li>
                <li class="portfolio-info__stack-item">Yandex Maps API</li>
              </ul>
              <ul class="portfolio-info__icons-list">
                <li class="portfolio-info__icons-item">
                  <a
                    href="https://github.com/mishapark/mishapark.github.io"
                    target="_blank"
                    class="fa fa-github-alt"
                    rel="noreferrer"
                  ></a>
                </li>
                <li class="portfolio-info__icons-item">
                  <a
                    href="https://mishapark.github.io/dist/"
                    target="_blank"
                    class="fa fa-external-link"
                    rel="noreferrer"
                  ></a>
                </li>
              </ul>
            </div>
          </li>
          <li class="portfolio-item">
            <div class="portfolio-pic">
              <a
                href="https://mishapark.github.io/air-asia/"
                class="portfolio-pic__link"
                target="_blank"
                rel="noreferrer"
              ></a>
              <img
                src={airAsia}
                alt=""
                class="portfolio-img"
              />
            </div>
            <div class="portfolio-info">
              <h3 class="portfolio-info__subtitle">Featured Project</h3>
              <h2 class="portfolio-info__title">AirAsia</h2>
              <p class="portfolio-info__text">
                Created a travel website containing three pages using a website
                template and Perfect Pixel
              </p>
              <ul class="portfolio-info__stack">
                <li class="portfolio-info__stack-item">HTML</li>
                <li class="portfolio-info__stack-item">CSS</li>
                <li class="portfolio-info__stack-item">Perfect Pixel</li>
              </ul>
              <ul class="portfolio-info__icons-list">
                <li class="portfolio-info__icons-item">
                  <a
                    href="https://github.com/mishapark/air-asia"
                    target="_blank"
                    class="fa fa-github-alt"
                    rel="noreferrer"
                  ></a>
                </li>
                <li class="portfolio-info__icons-item">
                  <a
                    href="https://mishapark.github.io/air-asia/"
                    target="_blank"
                    class="fa fa-external-link"
                    rel="noreferrer"
                  ></a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default WebPortfolio;
