import React from 'react';

function EducationCard({ school, major, date, details, cert }) {
  return (
    <div class="art-timeline-item">
      <div class="art-timeline-mark-light"></div>
      <div class="art-timeline-mark"></div>

      <div class="art-a art-timeline-content">
        <div class="art-card-header">
          <div class="art-left-side">
            <h5>{school}</h5>
            <div class="art-el-suptitle mb-15">{major}</div>
          </div>
          <div class="art-right-side">
            <span class="art-date">{date}</span>
          </div>
        </div>

        {details.map((detail) => (
          <p>{`- ${detail}`}</p>
        ))}

        {cert && (
          <a
            data-fancybox="diplome"
            href={cert}
            class="art-link art-color-link art-w-chevron"
          >
            Certificate
          </a>
        )}
      </div>
    </div>
  );
}

export default EducationCard;
