import React from 'react';

function WorkCard({ company, role, date, duties }) {
  return (
    <div class="art-timeline-item">
      <div class="art-timeline-mark-light"></div>
      <div class="art-timeline-mark"></div>

      <div class="art-a art-timeline-content">
        <div class="art-card-header">
          <div class="art-left-side">
            <h5>{company}</h5>
            <div class="art-el-suptitle mb-15">{role}</div>
          </div>
          <div class="art-right-side">
            <span class="art-date">{date}</span>
          </div>
        </div>
        {duties.map((duty) => (
          <div>{`- ${duty}`}</div>
        ))}
      </div>
    </div>
  );
}

export default WorkCard;
