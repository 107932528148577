import React from 'react';

function SkillSet({ title, skills }) {
  return (
    <div className="row2-col">
      <h1 className="row2-title">{title}</h1>
      <ul className="row2-list">
        {skills.map((skill) => (
          <li className="row2-list__item">{skill}</li>
        ))}
      </ul>
    </div>
  );
}

export default SkillSet;
