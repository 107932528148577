import React from 'react';
import MobileProject from './MobileProject';
import FlutterApps from '../../assets/img/android/flutterApps.png';
import DotaTactix from '../../assets/img/iphone/dotatactix.png';
import Trendify from '../../assets/img/iphone/trendify.png';

function MobilePortfolio() {
  return (
    <div class="block portfolio iphone-block">
      <div class="portfolio-title">Some Things I've built</div>
      <div class="portfolio-container">
        <ul class="iphone-portfolio">
          <li class="iphone-project">
            <MobileProject
              image={DotaTactix}
              title={'DotaTactix'}
              subtitle={'AI-powered SwiftUI app'}
              appstore={'https://apps.apple.com/us/app/dotatactix/id6474991131'}
              desc={
                'DotaTactix is an AI-powered item build & game strategy generator that gives Dota 2 players personalized build/strategy based on their hero, lane, ally, and enemy heroes. It keeps players on top of the latest news from the world of Dota and provides the latest patch details!'
              }
              stack={['Swift', 'SwiftUI', 'Combine', 'Core Data', 'OpenAI']}
              link={'https://github.com/mishapark/DotaTactix'}
            />
            <MobileProject
              image={Trendify}
              title={'Trendify'}
              subtitle={'Kodeco Capstone project'}
              desc={
                'Trendify is an innovative iOS application crafted using SwiftUI, designed to offer a seamless and visually appealing shopping experience for fashion-forward men.'
              }
              stack={['Swift', 'SwiftUI', 'XCTest']}
              link={'https://github.com/mishapark/Trendify'}
            />
            <MobileProject
              image={FlutterApps}
              title={'10 Flutter Apps'}
              subtitle={'Flutter Portfolio'}
              desc={
                'My first 10 Flutter applications I have created over period from Sep 2022 - June 2023. Portfolio includes such apps as Chat, Quiz, Custom Browser, Map, Custom Player, Notes app and more. While working on this portfolio I used 25+ packages implementing at least 8 different state management solutions. More details about each project in my github'
              }
              stack={['Dart/Flutter', 'BloC', 'Firebase']}
              link={'https://github.com/mishapark/flutter-projects'}
            />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default MobilePortfolio;
