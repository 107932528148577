import React, { useState, useEffect } from 'react';
import './Preloader.css';

const Preloader = () => {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const duration = 3000;
    let startTime;

    const animateProgress = (timestamp) => {
      if (!startTime) {
        startTime = timestamp;
      }

      const elapsedTime = timestamp - startTime;
      const newProgress = Math.min((elapsedTime / duration) * 120, 100);
      setProgress(newProgress);

      if (elapsedTime < duration) {
        requestAnimationFrame(animateProgress);
      } else {
        setLoading(false);
      }
    };

    requestAnimationFrame(animateProgress);

    return () => {
      cancelAnimationFrame(animateProgress);
    };
  }, []);

  return (
    <>
      {loading && (
        <div className="art-preloader">
          <h4>Mikhail Pak</h4>
          <div className="progress-container">
            <div
              className="progress-bar"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <div style={{ marginTop: '20px' }}>{Math.round(progress)}%</div>
        </div>
      )}
    </>
  );
};

export default Preloader;
