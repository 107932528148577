import React from 'react';
import Image from '../../assets/img/profile/user2.jpg';
import Resume from "../../assets/docs/Mikhail's Resume.pdf";

const Hero = () => {
  return (
    <div className="block section-wrapper hero">
      <button
        className="art-btn art-btn-md art-submit resume-btn"
        type="submit"
      >
        <a
          href={Resume}
          target="_blank"
          rel="noreferrer"
        >
          Resume
        </a>
      </button>
      <div className="section section-main">
        <h3 className="section-main__greeting">Hello, my name is</h3>
        <h1 className="section-main__title">
          Mikhail Pak <span class="waving-hand">👋</span>
        </h1>
        <h1 className="section-main__subtitle">I build iOS applications</h1>
        <p className="section-main__about">
          I'm a mobile developer specializing in building exceptional digital
          experiences using Swift and SwiftUI
        </p>
      </div>
      <div className="profile">
        <img
          src={Image}
          alt=""
          className="profilepic"
        />
        <div className="profilepic-frame"></div>
      </div>
    </div>
  );
};

export default Hero;
