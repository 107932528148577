import React from 'react';
import BootcampCard from './BootcampCard';
import kodeco from '../../assets/docs/kodeco.pdf';
import flutter from '../../assets/img/cert/flutter.pdf';

function Bootcamps() {
  return (
    <div class="block education">
      <div class="portfolio-title education-title">Bootcamps and Courses</div>
      <div class="art-timeline art-gallery">
        <BootcampCard
          company={'Kodeco'}
          name={'iOS Bootcamp (SwiftUI)'}
          date={'Aug 2023 - Present'}
          desc={
            'Learned SwiftUI, Modern concurrency and Testing. Built Capstone project that was awarded with Best Capstone Project UI Design'
          }
          cert={kodeco}
        />
        <BootcampCard
          company={'Skillbox'}
          name={'Flutter bootcamp'}
          date={'Feb 2023 - May 2023'}
          desc={
            'Learned Flutter and Dart. Built multiple apps working with 25+ packages'
          }
          cert={''}
        />
        <BootcampCard
          company={'Udemy'}
          name={'The Complete Flutter Development Bootcamp with Dart'}
          date={'Sep 2022'}
          desc={'Learned basics of Flutter and Dart.'}
          cert={flutter}
        />
        {/* <BootcampCard
          company={'LoftSchool'}
          name={'React Developer'}
          date={'Sep 2021'}
          desc={'Built a solid foundation in ReactJS'}
          cert={'https://loftschool.com/diploma/DK1632544666/en/pdf'}
        />
        <BootcampCard
          company={'FreeCodeCamp'}
          name={'JavaScript Algorithms and Data Structures'}
          date={'Aug 2021'}
          desc={
            ' Deepened my knowledge in JS to have a better understanding of how it works'
          }
          cert={
            'https://www.freecodecamp.org/certification/east116/javascript-algorithms-and-data-structures'
          }
        />
        <BootcampCard
          company={'LoftSchool'}
          name={'Web Programmer'}
          date={'Apr 2020 - Aug 2020'}
          desc={
            'Completed 3 web development courses and created 3 projects using the latest technologies'
          }
          cert={'./docs/filename.pdf'}
        /> */}
      </div>
    </div>
  );
}

export default Bootcamps;
