/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGitSquare,
  faInstagram,
  faSkype,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

function Footer() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const scrollFunc = () => {
      const yOffset = window.scrollY;

      if (yOffset > 0) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    document.addEventListener('scroll', scrollFunc);
    return () => {
      document.removeEventListener('scroll', scrollFunc);
    };
  }, []);

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;

    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 10);
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    scrollToTop();
  };

  return (
    <footer className="footer">
      <p className="footer-text">© 2024 Mikhail Pak</p>
      <a
        className={`top-link ${showButton ? 'show' : 'hide'}`}
        href="#top"
        onClick={handleButtonClick}
      >
        👆
      </a>
      <div className="socials emailadd">
        <a
          href="mailto:packmisha@gmail.com"
          className="emailadd-link"
        >
          packmisha@gmail.com
        </a>
      </div>
      <div className="socials socicons">
        <a
          href="https://linkedin.com/in/mikhailpak"
          target="_blank"
          className="fa fa-linkedin"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a
          href="https://github.com/mishapark"
          target="_blank"
          className="fa fa-github"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faGitSquare} />
        </a>
        <a
          href="https://join.skype.com/invite/stQJTpoW9D5z"
          className="fa fa-skype"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faSkype} />
        </a>
        <a
          href="https://www.instagram.com/_parkmisha/"
          className="fa fa-instagram"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
