import React from 'react';
import SkillSet from './SkillSet';

function Skills() {
  return (
    <div className="block">
      <div className="art-a art-card">
        <div className="about-section">
          <div className="about-row row2">
            <div className="row-title">Skills</div>
            <div className="row-info">
              <SkillSet
                title={'LANGUAGES'}
                skills={['Swift', 'Dart', 'JavaScript', 'PL/SQL']}
              />
              <SkillSet
                title={'FRAMEWORKS'}
                skills={[
                  'SwiftUI',
                  'XCTest',
                  'Flutter',
                  'React JS',
                  'React Native',
                ]}
              />
              <SkillSet
                title={'DATABASE'}
                skills={['Firebase', 'SQLite', 'MongoDB']}
              />
              <SkillSet
                title={'TOOLS'}
                skills={[
                  'XCode',
                  'VSCode',
                  'Git/Github',
                  'Figma',
                  'AdobeXD',
                  'Photoshop',
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
